import React from 'react';
import * as Sentry from '@sentry/react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';

const sentry_dsn_tag = document.querySelector('meta[name="sentry-frontend-dsn"]') as HTMLMetaElement;
if (sentry_dsn_tag?.content) {
    Sentry.init({
        dsn: sentry_dsn_tag.content,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect: React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
            Sentry.httpClientIntegration(),
        ],
        sendDefaultPii: true,
        tracesSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
    });
}

export const setSentryUser = (id?: string, email?: string) => {
    try {
        if (id && email) {
            Sentry.setUser({ id: id, email: email });
        }
    } catch (error) {
        console.error(error);
    }
};
